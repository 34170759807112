import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { sectionList } from '../../constants/queryFragments';
import GlobalStyles from '../../styles/globalStyles';
import loadable from '@loadable/component';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import * as Styled from './commonPageTemplateStyles';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import Hamburger from '../../images/icon-hamburger.png';
import PageLeftMenu from '../../common/pageLeftMenu/pageLeftMenu';
import BlockLeftPanel from '../../common/blockLeftPanel/blockLeftPanel';
import TopBanner from '../../common/topBanner/topBanner';
import Footer from '../../components/footer/footer';
import SubFooter from '../../components/subFooter/subFooter';
import { Helmet } from 'react-helmet';
import LogoOG from '../../images/logo-og.jpg';

const CommonPageTemplate = ({ data, location }) => {
  React.useEffect(() => {
    if (!location?.hash) {
      window.scrollTo(0, 0);
    }
  }, []);
  const path = location?.pathname?.split('/')[1];
  const [showMenu, setShowMenu] = useState(false);
  const handleMenu = (event) => {
    setShowMenu(!event);
  };
  const [showRequestCallBackProp, setShowRequestCallBackProp] = useState(false);
  const handleRequestCallBack = () => {
    setShowRequestCallBackProp(true);
  };
  let pageType = location?.pathname?.split('/').filter((n) => n)[0];
  const hamburgerHeader = {
    residential: 'menu - residential',
    commercial: 'menu - commercial',
    about: 'menu - about columbia',
    contact: 'menu-contact',
  };
  const sectionsArray =
    data?.allContentfulColumbiaFuel?.edges[0]?.node?.sections;
  return (
    <React.Fragment>
      <GlobalStyles />
      <Helmet>
        <title>
          {data?.allContentfulColumbiaFuel?.edges[0]?.node?.title ??
            'Columbia Fuels :: Home Heating Oil and Propane Supplier'}
        </title>
        {data?.allContentfulColumbiaFuel?.edges[0]?.node?.metadata && (
          <meta
            name="description"
            content={data?.allContentfulColumbiaFuel?.edges[0]?.node?.metadata}
          ></meta>
        )}
        {data?.allContentfulColumbiaFuel?.edges[0]?.node?.hasIndex ===
          false && <meta name="robots" content="noindex" />}

        <meta name="twitter:image" content={LogoOG}></meta>
        <meta property="og:image" content={LogoOG}></meta>
      </Helmet>
      <DefaultHeaderMenu
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
      />
      <BootstrapNavbar
        showRequestCallBackProp={showRequestCallBackProp}
        setShowRequestCallBackProp={setShowRequestCallBackProp}
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock path={location.pathname} location={location} />
      <Styled.CommonPageContainer>
        <Styled.InnerContainer>
          <Styled.BodyLeft>
            <PageLeftMenu path={location.pathname} />
            <BlockLeftPanel path={location.pathname} />
          </Styled.BodyLeft>
          <Styled.BodyRight>
            <Styled.Menubar
              onClick={() => {
                handleMenu(showMenu);
              }}
            >
              <Styled.ArrowImage src={Hamburger} alt="Hamburger icon" />
              {hamburgerHeader[pageType]}
            </Styled.Menubar>
            {showMenu && (
              <Styled.MenubarBody>
                <PageLeftMenu path={location.pathname} />
                <BlockLeftPanel path={location.pathname} />
              </Styled.MenubarBody>
            )}
            {sectionsArray &&
              sectionsArray?.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  const SectionComponent = loadable(() =>
                    import(`../../components/${section?.type}/${section?.type}`)
                  );
                  return (
                    <SectionComponent
                      key={index}
                      sectionData={section}
                      handleRequestCallBack={handleRequestCallBack}
                      location={location}
                    />
                  );
                }
              })}
            <Styled.CardStyle>
              <BlockLeftPanel path={location.pathname} />
            </Styled.CardStyle>
          </Styled.BodyRight>
        </Styled.InnerContainer>
      </Styled.CommonPageContainer>
      <Footer showProducts={false} />
      <SubFooter />
    </React.Fragment>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulFaqs: Boolean!
    $contentfulContentList: Boolean!
    $contentfulListImagesWithPath: Boolean!
    $contentfulImageWithPath: Boolean!
  ) {
    allContentfulColumbiaFuel(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          node_locale
          hasIndex
          metadata
          sections {
            ...PageContent @include(if: $contentfulContent)
            ...FAQContent @include(if: $contentfulFaqs)
            ...ContentList @include(if: $contentfulContentList)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
            ...ImageWithPath @include(if: $contentfulImageWithPath)
          }
        }
      }
    }
  }
`;
