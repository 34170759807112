import * as React from 'react';
import isExternalUrl, { isExternalUrlHref,addTrailingSlash } from '../../utils';
import * as Styled from './blockLeftPanelStyles';
import { graphql, useStaticQuery } from 'gatsby';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const BlockLeftPanel = ({ path }) => {
  let result = path.split('/').filter((n) => n);
  const commonCard = useStaticQuery(graphql`
    query commonCardQuery {
      allContentfulColumbiaFuel(
        filter: { identifier: { type: { eq: "commonCardsSection" } } }
      ) {
        edges {
          node {
            title
            name
            sections {
              ... on ContentfulCards {
                id
                title
                type
                carddetails {
                  title
                  type
                  headerText
                  path
                  image {
                    file {
                      fileName
                      url
                    }
                  }
                  description {
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const commonCardSections =
    commonCard.allContentfulColumbiaFuel.edges[0].node.sections;
  const commonCardData = commonCardSections?.filter(
    (section) => section.type === result[0]
  )[0];
  const commonCardsList = commonCardData.carddetails;

  return (
    <>
      {commonCardsList.map((cards, index) =>
        cards.path !== '!' ? (
          <Styled.Anchor
            href= {isExternalUrlHref(cards.path)?cards.path: addTrailingSlash(process.env.GATSBY_DOMAIN_URL + cards?.path)}
            target={isExternalUrl(cards.path) ? '_blank' : '_self'}
            key={index}
          >
            <Styled.BlockContainer>
              <Styled.BlockTitle>{cards.title}</Styled.BlockTitle>
              <Styled.BlockArea>
                <Styled.CardCol>
                  {cards.title !== 'eBilling' && cards.image && (
                    <Styled.Img src={cards?.image?.file?.url} />
                  )}
                  {cards?.headerText.trim() && (
                    <Styled.Heading3>{cards?.headerText}</Styled.Heading3>
                  )}
                  {cards?.description?.description.trim() !== '!' && (
                    <Styled.Paragraph>
                      {cards?.description?.description}
                    </Styled.Paragraph>
                  )}
                  {cards.title === 'eBilling' && cards.image && (
                    <Styled.Img
                      $width="100%"
                      $floatNone="true"
                      src={cards?.image?.file?.url}
                    />
                  )}
                </Styled.CardCol>
              </Styled.BlockArea>
            </Styled.BlockContainer>
          </Styled.Anchor>
        ) : (
          <Styled.NoAnchorDiv key={index}>
            <Styled.BlockContainer>
              <Styled.BlockTitle>{cards.title}</Styled.BlockTitle>
              <Styled.BlockArea>
                <Styled.CardCol>
                  {cards.title !== 'eBilling' && cards.image && (
                    <Styled.Img src={cards?.image?.file?.url} />
                  )}
                  {cards?.headerText.trim() && (
                    <Styled.Heading3>{cards?.headerText}</Styled.Heading3>
                  )}
                  {cards?.description?.description.trim() !== '!' && (
                    <Styled.Paragraph>
                      {cards?.description?.description}
                    </Styled.Paragraph>
                  )}
                </Styled.CardCol>
              </Styled.BlockArea>
            </Styled.BlockContainer>
          </Styled.NoAnchorDiv>
        )
      )}
    </>
  );
};

export default BlockLeftPanel;
