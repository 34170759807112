import * as React from 'react';
import {
  RightMenuPanelContainer,
  ListContainer,
  LiContainer,
  LinkStyle,
  LinkStyleInternal,
  ArrowImg,
  Arrow,
  SubMenuList,
  SubMenuListItem,
  SubMenuAnchor,
  SubSubMenuAnchor,
  SubMenuAnchorInternal,
  SubSubMenuAnchorInternal,
  ActiveLink,
  ActiveSubLink,
  ActiveSubSubLink,
} from './pageLeftMenuStyle';
import useMenus from '../../hooks/useMenus';
import isExternalUrl, {
  isExternalUrlHref,
  addTrailingSlash,
} from '../../utils';
import { useLocation } from '@reach/router';
import { Match } from '@reach/router';

const PageLeftMenu = ({ path }) => {
  let result = path.split('/').filter((n) => n);
  const menuItem = useMenus().navigations.filter((navigation) => {
    return navigation.type === result[0];
  })[0];
  const location = useLocation();
  const level0 = location.pathname
    .replaceAll('-', ' ')
    .split('/')
    .filter((n) => n)[0];
  const level1 = location.pathname
    .replaceAll('-', ' ')
    .split('/')
    .filter((n) => n)[1];
  const level2 = location.pathname
    .replaceAll('-', ' ')
    .split('/')
    .filter((n) => n)[2];

  const validateRoute = (route) => {
    const menu = menuItem.subNavigations;
    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i].subMenusWithPaths?.length; j++) {
        if (menu[i].subMenusWithPaths[j].path === route) {
          return true;
        }
      }
    }
    return false;
  };
  const checkAndAppendId = (path) => {
    const char = path.charAt(path.length - 1);
    return char !== '/' ? path + '/#title' : path + '#title';
  };
  return (
    <RightMenuPanelContainer>
      <ListContainer>
        {menuItem?.subNavigations?.map((subNavigation, index) => {
          return (
            <LiContainer key={index}>
              {isExternalUrl(subNavigation.textWithPath.path) ? (
                <LinkStyle
                  getProps={({ isCurrent }) => {
                    return isCurrent ? { style: ActiveLink } : {};
                  }}
                  key={index}
                  href={
                    isExternalUrlHref(subNavigation.textWithPath.path)
                      ? subNavigation.textWithPath.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL +
                            subNavigation.textWithPath.path
                        )
                  }
                  target="_blank"
                >
                  {subNavigation?.textWithPath?.text}
                </LinkStyle>
              ) : (
                <Match path={subNavigation.textWithPath.path}>
                  {({ match }) => (
                    <LinkStyleInternal
                      style={
                        match
                          ? validateRoute(subNavigation.textWithPath.path)
                            ? {}
                            : ActiveLink
                          : {}
                      }
                      to={
                        isExternalUrlHref(subNavigation.textWithPath.path)
                          ? subNavigation.textWithPath.path
                          : checkAndAppendId(
                              process.env.GATSBY_DOMAIN_URL +
                                subNavigation.textWithPath.path
                            )
                      }
                    >
                      {subNavigation?.textWithPath?.text}
                    </LinkStyleInternal>
                  )}
                </Match>
              )}

              {subNavigation?.subMenusWithPaths &&
              subNavigation?.textWithPath?.text
                ?.toLowerCase()
                .includes(
                  level1 === 'hse'
                    ? 'health, safety & environment'
                    : level0 === 'commercial' && level1 === 'promotions'
                    ? 'partnerships'
                    : level1
                ) ? (
                <SubMenuList>
                  <SubMenuListItem>
                    {subNavigation.subMenusWithPaths.map((subMenu, index) => {
                      return (
                        <React.Fragment key={index}>
                          {isExternalUrl(
                            subMenu.path ?? subMenu.textWithPath.path
                          ) ? (
                            <SubMenuAnchor
                              key={index}
                              target="_blank"
                              getProps={({ isCurrent }) => {
                                return isCurrent
                                  ? { style: ActiveSubLink }
                                  : {};
                              }}
                              href={
                                isExternalUrlHref(
                                  subMenu.path ?? subMenu.textWithPath.path
                                )
                                  ? subMenu.path ?? subMenu.textWithPath.path
                                  : checkAndAppendId(
                                      process.env.GATSBY_DOMAIN_URL +
                                        (subMenu.path ??
                                          subMenu.textWithPath.path)
                                    )
                              }
                            >
                              {subMenu.text ?? subMenu.textWithPath.text ?? ''}
                            </SubMenuAnchor>
                          ) : (
                            <Match
                              path={subMenu.path ?? subMenu.textWithPath.path}
                            >
                              {({ match }) => (
                                <SubMenuAnchorInternal
                                  style={match ? ActiveSubLink : {}}
                                  to={
                                    isExternalUrlHref(
                                      subMenu.path ?? subMenu.textWithPath.path
                                    )
                                      ? subMenu.path ??
                                        subMenu.textWithPath.path
                                      : checkAndAppendId(
                                          process.env.GATSBY_DOMAIN_URL +
                                            (subMenu.path ??
                                              subMenu.textWithPath.path)
                                        )
                                  }
                                >
                                  {subMenu.text ??
                                    subMenu.textWithPath.text ??
                                    ''}
                                </SubMenuAnchorInternal>
                              )}
                            </Match>
                          )}
                          {(subMenu?.subMenusWithPaths &&
                            subMenu.text?.toLowerCase().includes(level2)) ||
                            (subMenu?.textWithPath?.text
                              ?.toLowerCase()
                              .includes(level2) &&
                              subMenu?.subMenusWithPaths?.map(
                                (subSubMenu, index) => {
                                  return isExternalUrl(subSubMenu.path) ? (
                                    <SubSubMenuAnchor
                                      getProps={({ isCurrent }) => {
                                        return isCurrent
                                          ? { style: ActiveSubSubLink }
                                          : {};
                                      }}
                                      href={
                                        isExternalUrlHref(subSubMenu.path)
                                          ? subSubMenu.path
                                          : checkAndAppendId(
                                              process.env.GATSBY_DOMAIN_URL +
                                                subSubMenu.path
                                            )
                                      }
                                      target={
                                        isExternalUrl(subSubMenu.path)
                                          ? '_blank'
                                          : '_self'
                                      }
                                      key={index}
                                    >
                                      {subSubMenu.text}
                                    </SubSubMenuAnchor>
                                  ) : (
                                    <Match path={subSubMenu.path}>
                                      {({ match }) => (
                                        <SubSubMenuAnchorInternal
                                          style={match ? ActiveSubSubLink : {}}
                                          to={
                                            isExternalUrlHref(subSubMenu.path)
                                              ? subSubMenu.path
                                              : checkAndAppendId(
                                                  process.env
                                                    .GATSBY_DOMAIN_URL +
                                                    subSubMenu.path
                                                )
                                          }
                                          key={index}
                                        >
                                          {subSubMenu.text}
                                        </SubSubMenuAnchorInternal>
                                      )}
                                    </Match>
                                  );
                                }
                              ))}
                        </React.Fragment>
                      );
                    })}
                  </SubMenuListItem>
                </SubMenuList>
              ) : null}
            </LiContainer>
          );
        })}
      </ListContainer>
    </RightMenuPanelContainer>
  );
};

export default PageLeftMenu;
